import React, { useEffect, useState, useRef, useCallback } from "react"
import ReactDOM from "react-dom"
import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';

function mmfxTemplate(shapes, canvas, uriEncodedModel) {
    return `<?xml version="1.0" encoding="UTF-8"?>
<mmfx type="mview">
    <mid value="${uuidv4()}" />
    <rank value="1002a1n" />
    <input-count value="${shapes.length}" />
    <flt name="shape-composition">
        <template id="${uuidv4()}">
            <ftype value="multiview" />
            <top_shape_mode value="auto"/>
            <propdefs>
                <propdef name="bg_color" type="int32">
                    <key>default</key>
                    <int32>255</int32>
                </propdef>
                <propdef name="bg_image_url" type="string" />
                <propdef name="bg_image_alpha" type="float32">
                    <key>default</key>
                    <float32>1.0</float32>
                </propdef>
                <propdef name="duration" type="float32">
                    <key>default</key>
                    <float32>1.0</float32>
                </propdef>
            </propdefs>
            <shapes>
                <!-- background -->
                <rect id="bg-rect" width="2vw" height="2vh" />
                <image id="bg-image" style="width: 2vw; height: 2vh; object-fit: cover" />
                <!-- video -->${shapes.map((shape, idx) => (`
                <video id="video-${shape.id}" x="${shape.x}vw" y="${shape.y}vh" width="${shape.width}vw" height="${shape.height}vh" style="object-fit: cover" channel="${idx}" />`
    )).join('')}
            </shapes>
            <animations>
                <!-- in -->${shapes.map((shape, idx) => (`
                <animation shape="video-${shape.id}" attr="beta" easing="easeInOutSine" phase="in" door="${idx}" from="1.0" to="0.0" duration="1.0t" />`
    )).join('')}
                <!-- out -->${shapes.map((shape, idx) => (`
                <animation shape="video-${shape.id}" attr="beta" easing="easeInOutSine" phase="out" door="${idx}" from="0.0" to="1.0" duration="1.0t" />`
    )).join('')}
            </animations>
            <bindings>
                <binding shape="bg-rect" attr="color">
                    <getprop name="bg_color" />
                    <tocolor />
                </binding>
                <binding shape="bg-image" attr="src">
                    <getprop name="bg_image_url" />
                </binding>
                <binding shape="bg-image" attr="alpha">
                    <getprop name="bg_image_alpha"/>
                </binding>
            </bindings>
            <editor>
                <section>
                    <color prop="bg_color">
                        <title lang="en">Background Color</title>
                        <title lang="fr">Couleur de fond</title>
                    </color>
                    <image prop="bg_image_url">
                        <title lang="en">Background Image</title>
                        <title lang="fr">Image de fond</title>
                    </image>
                    <range prop="bg_image_alpha" min="0.0" max="1.0">
                        <title lang="en">Background Image Opacity</title>
                        <title lang="fr">Opacité de l'image de fond</title>
                    </range>
                    <duration prop="duration">
                        <title lang="en">Transition Duration</title>
                        <title lang="fr">Durée de transition</title>
                    </duration>
                </section>
            </editor>
        </template>
    </flt>
    <thumbnail format="png" width="${canvas.width}" height="${canvas.height}" rendering="composed">${canvas.toDataURL('image/png').split(',', 2)[1]}</thumbnail>
    <!-- model=${uriEncodedModel} -->
</mmfx>`;
}

function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
}

function serializeModel(model) {
    return encodeURIComponent(btoa(JSON.stringify(model)));
}

function deserializeModel(str) {
    if (str === null || str.length === 0) return null;

    try {
        return JSON.parse(atob(decodeURIComponent(str)));
    } catch (err) {
        return {};
    }
}

const defaultItem = () => ({ height: 1, visible: true });
const defaultColumn = () => ({
    width: 1,
    items: [{ ...defaultItem() }]
});

const DEFAULT_MODEL = {
    width: 1280,
    height: 720,
    backgroundColor: "#000000",
    foregroundColor: "#FFD200",
    safeAreaPadding: 0,
    padding: 32,
    margin: 16,
    rows: [{
        height: 4,
        columns: [
            { ...defaultColumn(), width: 2 },
            { ...defaultColumn() }
        ]
    }, {
        height: 1,
        columns: [
            { ...defaultColumn() }
        ]
    }]
};

function Grid(props) {
    const { model } = props;
    return (
        <div id="stage" style={{ background: model.backgroundColor, width: model.width + 'px', height: model.height + 'px', padding: model.padding + 'px', paddingBottom: (+model.padding + +model.safeAreaPadding) + 'px', transform: `scale(${props.scale})` }}>
            {model.rows.map((row, rowIndex) =>
                <div key={`row${rowIndex}`} className="stage-row" style={{ flexGrow: row.height }}>
                    {row.columns.map((column, columnIndex) =>
                        <div key={`row${rowIndex}-column${columnIndex}`} className="stage-column" style={{ flexGrow: column.width }}>
                            {column.items.map((item, idx) =>
                                <div key={`row${rowIndex}-column${columnIndex}-item${idx}`} className={`stage-item ${item.visible ? 'stage-item__visible' : 'stage-item__hidden'}`} style={{
                                    background: model.foregroundColor,
                                    flexGrow: item.height,
                                    margin: model.margin + 'px'
                                }}>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

function Input({ label, ...rest }) {
    return <div className="form-group row">
        <label className="col-sm-6 col-form-label">
            {label}
        </label>
        <div className="col-sm-6">
            <input className="form-control" {...rest} />
        </div>
    </div>
}

function Stepper({
    label,
    inc,
    dec,
    value,
    min,
    max,
}) {
    return (
        <div className="form-group row align-items-center">
            <label className="col-sm-6 stepper-label">{label}</label>
            <div className="col-sm-6">
                <div className={"stepper"}>
                    <button
                        className="btn btn-primary"
                        onClick={dec}
                        disabled={value <= min}
                    >
                        -
                </button>
                    <span>{value}</span>
                    <button
                        className="btn btn-primary"
                        onClick={inc}
                        disabled={value >= max}
                    >
                        +
                </button>
                </div>
            </div>
        </div>
    );
}

function App(props) {
    const { initialModel, prefilledName } = props;
    const [model, setModel] = useState(initialModel);
    const gridContainerRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [uriEncodedModel, setUriEncodedModel] = useState(serializeModel(initialModel));

    useEffect(() => {
        setUriEncodedModel(serializeModel(model));
    }, [model]);
    console.log(initialModel);

    useEffect(() => {
        const scaleStage = () => {
            if (gridContainerRef.current) {
                const computedStyle = window.getComputedStyle(gridContainerRef.current);
                const xScale = parseInt(computedStyle.width) / model.width;
                const yScale = parseInt(computedStyle.height) / model.height;
                console.log("scaling");
                setScale(Math.min(xScale, yScale));
            }
        }
        
        window.addEventListener("resize", scaleStage);

        scaleStage();

        return () => window.removeEventListener("resize", scaleStage);
    }, [gridContainerRef, model.width, model.height]);

    return (
        <div className="app-container">
            <div className="sidebar">
                <div className={`controls`}>
                    <div className="actions">
                    <a className="btn btn-light mr-2" href={'./?model=' + uriEncodedModel}>Share</a>
                    <button id="download" className="btn btn-primary" onClick={e => {
                        const stage = document.getElementById('stage');
                        let shapes;
                    
                        html2canvas(stage, {
                            onclone: function (node) {
                                const cloned = node.getElementById('stage');
                                // reset scale for calculating dimensions
                                cloned.style.transform = "scale(1)";

                                const items = [...node.getElementsByClassName('stage-item__visible')];
                                const stageRect = cloned.getBoundingClientRect();

                                shapes = items.map((item, idx) => {
                                    const computedStyle = window.getComputedStyle(item);
                                    const width = parseInt(computedStyle.width, 10);
                                    const height = parseInt(computedStyle.height, 10);
                                    const rect = item.getBoundingClientRect();

                                    return {
                                        width,
                                        height,
                                        x: rect.x - stageRect.x,
                                        y: rect.y - stageRect.y,
                                        id: String.fromCharCode(97 + idx)
                                    };
                                }).map(shape => {
                                    const center_x = shape.x + (shape.width / 2);
                                    const center_y = shape.y + (shape.height / 2);
                                    const x = (center_x / (model.width / 2) - 1).toFixed(3);
                                    const y = (center_y / (model.height / 2) - 1).toFixed(3) * -1;
                                    const height = ((shape.height / model.height) * 2).toFixed(3);
                                    const width = ((shape.width / model.width) * 2).toFixed(3);

                                    return {
                                        width,
                                        height,
                                        x,
                                        y,
                                        id: shape.id,
                                    };
                                });
                            },
                            // canvas does not handle element scaling well, 
                            // and does not account for things like OS or browser zoom
                            // if this is not set.
                            scale: 1 / scale
                        }).then(canvas => {
                            const template = mmfxTemplate(shapes, canvas, uriEncodedModel);
                            const name = prompt('mmfx name?', prefilledName || Date.now());

                            if (name !== null) {
                                download(`tpl-mv-${name}-${shapes.length}.mmfx`, template);
                            }
                        }).catch(err => {
                            console.error(err);
                        });
                    }}>Download .mmfx</button>
                    </div>
                    <hr />
                    <Input label="Width" value={model.width} type="number" onChange={e => setModel({ ...model, width: Number(e.target.value) })} />
                    <Input label="Height" value={model.height} type="number" onChange={e => setModel({ ...model, height: Number(e.target.value) })} />
                    <Input label="Background Color" value={model.backgroundColor} type="color" onChange={e => setModel({ ...model, backgroundColor: e.target.value })} />
                    <Input label="Shape Color" value={model.foregroundColor} type="color" onChange={e => setModel({ ...model, foregroundColor: e.target.value })} />
                    <hr />
                    <Input label="Stage Padding" value={model.padding} type="number" min="0" onChange={e => setModel({ ...model, padding: Number(e.target.value) })} />
                    <Input label="Safe Area Padding" value={model.safeAreaPadding} type="number" min="0" onChange={e => setModel({ ...model, safeAreaPadding: Number(e.target.value) })} />
                    <Input label="Shape Margin" value={model.margin} type="number" min="0" onChange={e => setModel({ ...model, margin: Number(e.target.value) })} />
                    <hr />
                    <Stepper label="Rows" min={0} max={10} value={model.rows.length}
                        dec={e => setModel(prev => {
                            if (prev.rows.length === 0) return prev;

                            return { ...prev, rows: prev.rows.slice(0, -1) || [] };
                        })}
                        inc={e => setModel(prev => {
                            if (prev.rows.length === 0) {
                                return { ...prev, rows: [{ height: 1, columns: [{ ...defaultColumn() }] }] };
                            }

                            return { ...prev, rows: [...prev.rows, JSON.parse(JSON.stringify(prev.rows[prev.rows.length - 1]))] };
                        })} />
                    {model.rows.map((row, rowIndex) =>
                        <div key={rowIndex} style={{ background: '#ddd', margin: '1rem 0 0 0', padding: '8px' }}>
                            <p><strong>Row {rowIndex + 1}</strong></p>
                            <Stepper label="Columns" min={0} max={10} value={row.columns.length}
                                dec={e => setModel(prev => {
                                    const columns = prev.rows[rowIndex].columns;

                                    if (columns.length < 2) return { ...prev };

                                    prev.rows[rowIndex].columns = prev.rows[rowIndex].columns.slice(0, -1);

                                    return { ...prev };
                                })}
                                inc={e => setModel(prev => {
                                    const columns = prev.rows[rowIndex].columns;
                                    columns.push({ ...defaultColumn() });

                                    prev.rows[rowIndex].columns = columns;
                                    return { ...prev };
                                })} />
                            <Input label="Height" value={row.height} type="number" min="1" onChange={e => setModel(prev => {
                                const rows = [...model.rows];
                                rows[rowIndex].height = e.target.value;
                                return { ...model, rows };
                            })} />

                            {row.columns.map((column, columnIndex) =>
                                <div key={columnIndex} style={{ background: '#bbb', margin: '8px 0', padding: '4px 8px 2px 8px' }}>
                                    <p><strong>Column {columnIndex + 1}</strong></p>
                                    <Stepper label="Items" min={0} max={10} value={column.items.length}
                                        dec={e => setModel(prev => {
                                            const items = prev.rows[rowIndex].columns[columnIndex].items;

                                            if (items.length < 2) return { ...prev };

                                            prev.rows[rowIndex].columns[columnIndex].items = prev.rows[rowIndex].columns[columnIndex].items.slice(0, -1);

                                            return { ...prev };
                                        })}
                                        inc={e => setModel(prev => {
                                            const items = prev.rows[rowIndex].columns[columnIndex].items;
                                            items.push({ ...defaultItem() });

                                            prev.rows[rowIndex].columns[columnIndex].items = items;
                                            return { ...prev };
                                        })} />
                                    <Input label="Width" value={column.width} type="number" min="1" onChange={e => setModel(prev => {
                                        prev.rows[rowIndex].columns[columnIndex].width = Number(e.target.value);
                                        return { ...prev };
                                    })} />
                                    {column.items.map((item, itemIndex) =>
                                        <div key={itemIndex} style={{ background: '#eee', margin: '8px 0', padding: '0 8px' }}>
                                            <p><strong>Item {itemIndex + 1}</strong></p>
                                            <Input label="Height" value={item.height} min="1" type="number" onChange={e => setModel(prev => {
                                                prev.rows[rowIndex].columns[columnIndex].items[itemIndex].height = Number(e.target.value);
                                                return { ...prev };
                                            })} />
                                            <div className="form-group row">
                                                <div className="col-sm-6">Visible</div>
                                                <div className="col-sm-6">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="gridCheck1" checked={item.visible === true} onChange={e => setModel(prev => {
                                                            prev.rows[rowIndex].columns[columnIndex].items[itemIndex].visible = e.target.checked;
                                                            return { ...prev };
                                                        })} />
                                                        <label className="form-check-label" htmlFor="gridCheck1">
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            
            <div className="grid-container" ref={gridContainerRef} >
                <Grid model={model} scale={scale} />
            </div>
        </div>
    );
}

const params = (new URL(document.location)).searchParams;
const model = { ...DEFAULT_MODEL, ...deserializeModel(params.get('model')) };

ReactDOM.render(<App initialModel={model} prefilledName={params.get('name')} />, document.getElementById("root"))
